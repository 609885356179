import React from 'react'

import { AccordionDetails, AccordionSummary, Typography } from '@material-ui/core'

// import { MinusCircleIcon, PlusCircleIcon } from 'components/Icons'
import { FaIcon } from 'atomic/legacy/atm.fa-icon'

import * as S from './styles'

export type AccordionProps = {
  slug: string
  title: string
  children: JSX.Element
  expanded: string | false
  handleChange: (
    param: string
  ) => ((event: React.ChangeEvent<{}>, expanded: boolean) => void) | undefined
  bgWhite?: boolean
  type: string
}

const Accordion = ({ slug, title, children, expanded, handleChange, type }: AccordionProps) => {
  return (
    <S.MaterialAccordion
      expanded={expanded === `panel-${slug}`}
      onChange={handleChange(`panel-${slug}`)}
    >
      <AccordionSummary aria-controls={`${slug}-content`} id={`${slug}-header`} className={type}>
        <Typography variant="h4">{title}</Typography>
        {expanded === `panel-${slug}` ? (
          // <MinusCircleIcon />
          <FaIcon.ChevronUp />
        ) : (
          <FaIcon.ChevronDown />
          // <PlusCircleIcon />
        )}
      </AccordionSummary>

      <AccordionDetails>{children}</AccordionDetails>
    </S.MaterialAccordion>
  )
}

export default Accordion
