import styled from 'styled-components'

export const VideoContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 0 !important;
  padding: 0 !important;
  width: 100% !important;
  height: 100% !important;

  iframe {
    width: 100% !important;
    height: 100% !important;
  }

  /* width: 288px !important;
  height: 195px !important;
  position: relative !important;
  border-radius: 10px;
  margin: 0 auto;

  @media (min-width: 1024px) {
    width: 464px !important;
    height: 287px !important;
    margin-left: 55% !important;
    margin-bottom: 15px;
  }

  @media (min-width: 768px) {
    width: 288px;
    height: 195px;
    margin-left: 70%;
  } */
`

export const MedicalContainer = styled.div`
  height: auto;
  margin-bottom: 35px;

  .description {
    p {
      color: #302a38 !important;
      margin-bottom: 15px;
    }
  }

  /* width: 100%;
  overflow-x: hidden;
  padding-bottom: 40px;
  position: relative;

  h1,
  h2,
  h3,
  h4.h5,
  p,
  span,
  a {
    color: #302a38;
  }

  @media (max-width: 767px) {
    margin-top: 185px;
  }

  .no-padding-col {
    padding: 0px;
    padding-top: 5px;
  }

  .no-padding-bottom {
    margin-bottom: 0;
  } */
`

export const AccordionsContainer = styled.div`
  /* background-color: #f7f7f7; */
  padding-top: 50px;
  padding-bottom: 20px;
`

export const ChannelsContainer = styled.div`
  padding-top: 30px;
  padding-bottom: 20px;

  .channels-title {
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: left;
    color: #302a38;
  }

  .channels {
    margin-bottom: 30px;
  }
`
