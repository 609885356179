import React, { useState } from 'react'
import IndexLayout from 'site/src/components/org.layout/layout.component'

import TitleWithBreadcrumbRow from 'site/src/components/org.title-with-breadcrumb-row/title-with-breadcrumb-row.component'
import { graphql } from 'gatsby'
import { MedicalContainer, AccordionsContainer, VideoContainer } from './styled'

import Accordion from 'site/src/components/Accordion'
import Title from 'site/src/components/Title'
import { Button } from 'atomic'

// import { TextField, MenuItem } from '@material-ui/core'

import { Container, Grid } from '@material-ui/core'

export default props => {
  const [expanded, setExpanded] = useState(false)
  const { metadata } = props.data.cosmicjsLandingPages
  const handleChange = panel => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false)
  }

  return (
    <IndexLayout location={props.location}>
      <Container maxWidth="md">
        <TitleWithBreadcrumbRow
          addtionalDictionary={{
            '/area-medica': metadata.banner_destaque.titulo
          }}
        />

        <MedicalContainer>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Title variant="h5">{metadata.banner_destaque.titulo}</Title>
              <div
                className="description"
                dangerouslySetInnerHTML={{
                  __html: metadata.banner_destaque.descricao
                }}
              />
              <Button to="/medico/cadastro-medico" id="button-send-email-contact" kind="secondary">
                <span id="register">Cadastre-se</span>
              </Button>
            </Grid>
            <Grid item xs={12} sm={6}>
              <VideoContainer>
                <iframe
                  src={`https://www.youtube.com/embed/${metadata.banner_destaque.video}?autoplay=1&mute=1`}
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  title="Embedded youtube"
                />
              </VideoContainer>
            </Grid>
          </Grid>
        </MedicalContainer>
      </Container>
      <AccordionsContainer>
        <Container maxWidth="md">
          <Title variant="h5">Como funciona</Title>
          {metadata.faq
            .filter(x => x.channels.find(c => c === 'Website'))
            .map(({ question, answer }, index) => {
              return (
                <Accordion
                  type="medical-area"
                  bgWhite
                  key={index}
                  slug={index.toString()}
                  title={`${index + 1}. ${question}`}
                  expanded={expanded}
                  handleChange={handleChange}
                >
                  <p variant="body1">{answer}</p>
                </Accordion>
              )
            })}
        </Container>
      </AccordionsContainer>
    </IndexLayout>
  )
}

export const query = graphql`
  query AreaMedica {
    cosmicjsLandingPages(slug: { eq: "area-medica" }) {
      id
      metadata {
        banner_destaque {
          titulo
          descricao
          video
        }

        faq {
          question
          answer
          channels
        }
      }
    }
  }
`
