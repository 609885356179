import styled from 'styled-components'
import { Accordion } from '@material-ui/core'

export const MaterialAccordion = styled(Accordion)`
  margin-bottom: 16px;
  box-shadow: none !important;
  border-radius: 8px !important;
  width: 100% !important;

  &::before {
    content: unset !important;
  }

  &.Mui-expanded {
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.161);
    margin-bottom: 16px;
  }

  .MuiAccordionSummary-root {
    min-height: 56px;
    border-radius: 8px 8px 8px 8px;
    font-size: 16px;
    font-weight: 600;

    &.medical-area {
      background-color: ${props => props.theme.color.primaryLight};
    }

    &.Mui-expanded {
      min-height: 56px;
      background-color: ${props => props.theme.color.primary};
      border-radius: 8px 8px 0 0;
    }
  }

  .MuiTypography-h4 {
    font-size: 14px;
  }

  .MuiAccordionSummary-content {
    display: flex;
    justify-content: space-between;
    color: ${props => props.theme.color.primary};

    .medical-area {
    }

    > .MuiTypography-h4 {
      max-width: 90%;
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
    }

    > svg {
      width: 20px;
    }

    &.Mui-expanded {
      margin: 0;

      .MuiTypography-h4,
      svg {
        color: #f7f7f7 !important;
      }
    }
  }

  .MuiAccordionDetails-root {
    padding: 24px 24px 32px;
    border-radius: 0 0 8px 8px;
    color: #f7f7f7;
  }
`
